export const I18N_NAMESPACE: { [key: string]: string } = {
  NOT_FOUND: "notfound",
  ACTIVATE_EMAIL: "activateemail",
  GAME_PLAY_MODAL: "gameplaymodal",
  GENERAL: "general",
  LOGIN: "login",
  REGISTER: "register",
  FORGOT_PASSWORD: "forgotpassword",
  SEARCH: "search",
  GAME_CARD: "gamecard",
  IMG_ALT: "imgalt",
  LEVEL: "level",
  CATEGORIES: "categories",
  RANK_UP: "rankup",
  PAYMENTS: "payments",
  VENDOR: "vendors",
  JACKPOT: "jackpot",
  PROFILE: "profile",
  BONUSES: "bonuses",
  BALANCE: "balance",
  CONSENTS: "consents",
  CHANGE_PASSWORD: "changepassword",
  DOCUMENTS: "documents",
  HELP: "help",
  INFO: "info",
  MY_BETS: "mybets",
  BETTING_HISTORY: "bettinghistory",
  HISTORY: "history",
  PLAYER_LEVEL: "playerlevel",
  RESPONSIBLE_GAMBLING: "responsiblegambling",
  RESPONSIBLE_GAMBLING_LIMIT: "responsiblegamblinglimit",
  TRANSACTIONS: "transactions",
  PROMOTIONS: "promotions",
  RESET_PASSWORD: "resetpassword",
  CASINO_GAME_GRID: "casinogamegrid",
  CASINO: "casino",
  LAYOUT: "layout",
  NAV: "nav",
  BRAND: "brand",
  API: "api",
  GAMES_DATA_BOARD: "gamesdataboard",
  GAMES_SWIPER: "gamesswiper",
  SEO_TEXT: "seotext",
  GAME: "game",
  FOOTER: "footer",
  DEADSIMPLECHAT: "deadsimplechat",
  EVERYMATRIXERROR: "everymatrixerror",
  DEPOSIT: "deposit",
  WITHDRAWAL: "withdraw",
};
