"use client";
import { I18N_NAMESPACE } from "@/config/i18n";
import { useTranslations } from "next-intl";
import Image from "next/image";

const NotFound = () => {
  const t = useTranslations(I18N_NAMESPACE.NOT_FOUND);
  return (
    <div
      className="h-screen flex items-center justify-center bg-cover bg-center relative -mb-10 after:w-full after:h-full after:absolute after:top-0 after:left-0 after:bg-black/60"
      style={{ backgroundImage: `url(/img/maintenance-bg.png)` }}
    >
      <div className="p-4 max-w-3xl text-center text-white h-auto flex flex-col items-center relative z-50">
        <div className="mb-14 w-full">
          <Image
            src={"/img/logo-light.svg"}
            alt="logo"
            className="!w-full max-w-md !relative !h-auto mx-auto"
            loading="eager"
            fill
            objectFit="contain"
          />
        </div>

        <div className="content-info">
          <h1 className="h1 text-2xl md:text-4xl font-bold">
            {t("404-oops-page-not-found")}
          </h1>

          <div className="text-base text-white mt-6 md:mt-8">
            <p>
              {t("404-sorry-we-cannot-find-page")} <br />
              {t("404-don't-worry-though")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
